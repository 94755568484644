<script setup lang="ts">
    interface ProductPriceLabelProps {
        productPriceLabel?: string | null;
    }
    defineProps<ProductPriceLabelProps>();

    const appStore = useAppStore();
    const { locale, iso2Country, iso2Lang } = useLocales();
    await appStore.checkWebsite({ locale, iso2Country, iso2Lang });
    const { website } = storeToRefs(appStore);

    const globalPriceLabel = computed(() => website.value?.shop?.globalPriceLabel);
</script>
<template>
    <span class="atm-product-price-label">
        <template v-if="productPriceLabel">{{ productPriceLabel }}</template>
        <template v-else-if="globalPriceLabel">{{ globalPriceLabel }}</template>
        <template v-else>{{ $t('price.tax') }}:</template>
    </span>
</template>
